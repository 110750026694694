import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import ServiceWrapper from '../components/ServiceWrapper';
import { accessTokenState } from '../recoil/auth';

interface IPrivateRoute extends RouteProps {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
}

const PrivateRoute = ({
  component: Component,
  children,
  ...rest
}: IPrivateRoute) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ServiceWrapper>{<Component {...props} />}</ServiceWrapper>
      )}
    />
  );
};

export default PrivateRoute;
