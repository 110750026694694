import { atom, useResetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { PUBLIC_ROUTE } from '../routes/routes.constants';

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  // @ts-ignore
  persistence_UNSTABLE: { type: true },
});

export const refreshTokenState = atom({
  key: 'refreshTokenState',
  default: '',
  // @ts-ignore
  persistence_UNSTABLE: { type: true },
});

export const useLogout = () => {
  const history = useHistory();

  const resetAccessToken = useResetRecoilState(accessTokenState);
  const resetRefreshToken = useResetRecoilState(refreshTokenState);

  return () => {
    resetAccessToken();
    resetRefreshToken();
    history.push(PUBLIC_ROUTE.LANDING);
  };
};
