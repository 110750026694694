import React from 'react';
import loadable from '@loadable/component';
import {
  AppstoreOutlined,
  UserOutlined,
  CarryOutOutlined,
  QuestionCircleOutlined,
  PhoneOutlined,
  PicLeftOutlined,
  LineChartOutlined,
  AccountBookOutlined,
  FolderOpenOutlined,
  CustomerServiceOutlined,
  AudioOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { PUBLIC_ROUTE } from './routes.constants';
import { Redirect } from 'react-router';

export interface ISidebar {
  label: string;
  icon?: React.ReactNode;
}

export interface IRoute {
  exact?: boolean;
  visible?: boolean;
  path?: string;
  component?: any;
  children?: Array<IRoute>;
  sidebar?: ISidebar;
}

export const publicRoutes: Array<IRoute> = [
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import('../pages/auth/Login')),
  },
];

interface IPrivateRoute extends IRoute {}

export const privateRoutes: Array<IPrivateRoute> = [
  {
    exact: true,
    path: '/dashboard',
    component: loadable(() => import('../pages/Dashboard')),
    sidebar: {
      icon: <AppstoreOutlined />,
      label: '대시보드',
    },
  },
  {
    exact: true,
    path: '/user',
    component: loadable(() => import('../pages/user/user')),
    sidebar: {
      icon: <UserOutlined />,
      label: '회원 관리',
    },
    children: [
      {
        exact: true,
        path: '/user',
        sidebar: {
          label: '전체 회원 리스트',
        },
        component: loadable(() => import('../pages/user/user')),
      },
      {
        exact: true,
        path: '/composer',
        sidebar: {
          label: '작곡가 회원 리스트',
        },
        component: loadable(() => import('../pages/user/composer')),
      },
      {
        exact: true,
        path: '/composer/setting',
        sidebar: {
          label: '작곡가 회원 관리',
        },
        component: loadable(() => import('../pages/user/composer/setting')),
      },
    ],
  },
  {
    exact: true,
    path: '/feed',
    component: loadable(() => import('../pages/contents/feed')),
    sidebar: {
      icon: <PicLeftOutlined />,
      label: '피드 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '컨텐츠 피드 리스트',
        },
        component: loadable(() => import('../pages/contents/feed')),
      },
    ]
  },
  {
    exact: true,
    path: '/music',
    component: loadable(() => import('../pages/contents/music/all')),
    sidebar: {
      icon: <CustomerServiceOutlined />,
      label: '곡 관리',
    },
    children: [
      {
        exact: true,
        path: '/all',
        sidebar: {
          label: '전체 곡 리스트',
        },
        component: loadable(() => import('../pages/contents/music/all')),
      },
      {
        exact: true,
        path: '/hot',
        sidebar: {
          label: '인기 곡 리스트',
        },
        component: loadable(() => import('../pages/contents/music/hot')),
      },
      {
        exact: true,
        path: '/hidden',
        sidebar: {
          label: '숨겨진 곡 리스트',
        },
        component: loadable(() => import('../pages/contents/music/hidden')),
      },
      {
        exact: true,
        path: '/ready',
        sidebar: {
          label: '심사대기 곡 리스트',
        },
        component: loadable(() => import('../pages/contents/music/ready')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/contents/music/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/detail/:id',
        component: loadable(() => import('../pages/contents/music/detail')),
      },
    ]
  },
  {
    exact: true,
    path: '/record',
    component: loadable(() => import('../pages/contents/record/all')),
    sidebar: {
      icon: <AudioOutlined />,
      label: '녹음 관리',
    },
    children: [
      {
        exact: true,
        path: '/all',
        sidebar: {
          label: '전체 녹음 리스트',
        },
        component: loadable(() => import('../pages/contents/record/all')),
      },
      {
        exact: true,
        path: '/hidden',
        sidebar: {
          label: '숨긴 녹음 리스트',
        },
        component: loadable(() => import('../pages/contents/record/hidden')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/contents/record/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/event',
    component: loadable(() => import('../pages/event')),
    sidebar: {
      icon: <CarryOutOutlined />,
      label: '이벤트 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '이벤트 리스트',
        },
        component: loadable(() => import('../pages/event')),
      },
      {
        exact: true,
        visible: false,
        path: '/add',
        component: loadable(() => import('../pages/event/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/event/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/faq',
    component: loadable(() => import('../pages/faq')),
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: '자주묻는 질문 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '자주묻는 질문 리스트',
        },
        component: loadable(() => import('../pages/faq')),
      },
      {
        exact: true,
        visible: false,
        path: '/add',
        component: loadable(() => import('../pages/faq/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/faq/modify')),
      },
      {
        exact: true,
        path: '/category',
        sidebar: {
          label: '카테고리 리스트',
        },
        component: loadable(() => import('../pages/faq/category')),
      },
      {
        exact: true,
        visible: false,
        path: '/category/add',
        component: loadable(() => import('../pages/faq/category/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/category/modify/:id',
        component: loadable(() => import('../pages/faq/category/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/qna',
    component: loadable(() => import('../pages/qna')),
    sidebar: {
      icon: <PhoneOutlined />,
      label: '1:1 문의 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '1:1 문의 리스트',
        },
        component: loadable(() => import('../pages/qna')),
      },
      {
        exact: true,
        visible: false,
        path: '/detail/:id',
        component: loadable(() => import('../pages/qna/detail')),
      },
    ]
  },
  {
    exact: true,
    path: '/sales',
    component: loadable(() => import('../pages/sales/charge')),
    sidebar: {
      icon: <LineChartOutlined />,
      label: '매출 관리',
    },
    children: [
      {
        exact: true,
        path: '/charge',
        sidebar: {
          label: '충전 매출',
        },
        component: loadable(() => import('../pages/sales/charge')),
      },
      {
        exact: true,
        path: '/profit',
        sidebar: {
          label: '수익 매출',
        },
        component: loadable(() => import('../pages/sales/profit')),
      },
    ]
  },
  {
    exact: true,
    path: '/withdraw',
    component: loadable(() => import('../pages/withdraw')),
    sidebar: {
      icon: <AccountBookOutlined />,
      label: '정산 요청 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '정산 요청 리스트',
        },
        component: loadable(() => import('../pages/withdraw')),
      },
    ]
  },
  {
    exact: true,
    path: '/etc',
    component: loadable(() => import('../pages/etc/privacy')),
    sidebar: {
      icon: <FolderOpenOutlined />,
      label: '기타 관리',
    },
    children: [
      {
        exact: true,
        path: '/privacy',
        sidebar: {
          label: '개인정보처리방침 관리',
        },
        component: loadable(() => import('../pages/etc/privacy')),
      },
      {
        exact: true,
        path: '/terms',
        sidebar: {
          label: '이용약관 관리',
        },
        component: loadable(() => import('../pages/etc/terms')),
      },
      {
        exact: true,
        path: '/terms/creator',
        sidebar: {
          label: '크리에이터 이용약관 관리',
        },
        component: loadable(() => import('../pages/etc/terms/creator')),
      },
      {
        exact: true,
        path: '/push',
        sidebar: {
          label: '푸시 발송',
        },
        component: loadable(() => import('../pages/etc/push')),
      },
    ]
  },
  {
    exact: true,
    path: '/report',
    component: loadable(() => import('../pages/report')),
    sidebar: {
      icon: <WarningOutlined />,
      label: '신고 관리',
    },
  }
];
